import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../../assets/locales/en/translations.json';

const fallbackLng = ['en'];

const resources = {
    en: {
        translation: translationEN,
    },
};

const useInitiateI18 = (): void => {
    i18n.use(initReactI18next).init({
        resources,
        fallbackLng,

        detection: {
            checkWhitelist: true,
        },

        debug: false,

        interpolation: {
            escapeValue: false,
        },
    });
};
export default useInitiateI18;
