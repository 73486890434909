import './OurApps.styles.css';
import { useTranslation } from 'react-i18next';

import { MobileCheck } from '../../typings/common';

import { OurAppsData } from './OurApps.data';

const OurApps = ({ isMobile }: MobileCheck): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className={'app-container'}>
            <div className={isMobile ? 'our-apps-layout-m' : 'our-apps-layout'}>
                <h1>{t('ourApps.title')}</h1>
                <p>{t('ourApps.subTitle')}</p>
                <div className={'our-apps-cards'}>
                    {OurAppsData.map((card, idx) => (
                        <div className={'our-apps-card'} key={idx} style={{ backgroundColor: card.bgColor }}>
                            <img className={'our-apps-bg'} loading={'lazy'} src={card.bg} alt={card.bgName} />
                            <img className={'our-apps-logo'} loading={'lazy'} src={card.icon} alt={card.iconName} />
                            <p id={'our-apps-t1'} style={{ color: card.textColor }}>
                                {t(`ourApps.projects.${idx}.link`)}
                            </p>
                            <p id={'our-apps-t2'}>{t(`ourApps.projects.${idx}.project`)}</p>
                            <p id={'our-apps-t3'}>{t(`ourApps.projects.${idx}.description`)}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default OurApps;
