import './Team.styles.css';
import { useTranslation } from 'react-i18next';

import MaxImg from '../../assets/images/team/Max.png';
import Max2xImg from '../../assets/images/team/Max-2x.png';
import MaxMImg from '../../assets/images/team/Max-m.png';
import MaxM2xImg from '../../assets/images/team/Max-m-2x.png';
import { ReactComponent as StarIcon } from '../../assets/svg/team/star.svg';
import { MobileCheck } from '../../typings/common';

const Team = ({ isMobile }: MobileCheck): JSX.Element => {
    const { t } = useTranslation();
    const chips = new Array(6).fill(0).map((i, idx) => idx);
    return (
        <div className={isMobile ? 'team-layout-m' : 'team-layout'}>
            <div className={'app-container'}>
                <>
                    {isMobile ? (
                        <div className={'columns-m'}>
                            <h2 className={'team-title-m'}>{t('team.title')}</h2>
                            <div className={'team-star-m'}>
                                <picture>
                                    <source srcSet={`${MaxMImg} 1x, ${MaxM2xImg} 2x`} type="image/png" />
                                    <img className={'team-image-m'} loading={'lazy'} src={MaxMImg} alt={'Team'} />
                                </picture>
                            </div>
                            <h6 className={'team-member-m'}>{t('team.member')}</h6>
                            <p className={'team-bio-m'}>{t('team.bio')}</p>
                            <div className={'team-tags-m'}>
                                {chips.map((i) => (
                                    <p className={'team-chip-m'} key={i}>
                                        {t(`team.tags-m.${i}`)}
                                    </p>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className={'columns'}>
                            <div className={'team-first-column'}>
                                <h2 className={'team-title'}>{t('team.title')}</h2>
                                <h6 className={'team-member'}>{t('team.member')}</h6>
                                <p className={'team-bio'}>{t('team.bio')}</p>
                                <div className={'team-tags'}>
                                    {chips.map((i) => (
                                        <p className={'team-chip'} key={i}>
                                            {t(`team.tags.${i}`)}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <div className={'team-star'}>
                                <picture>
                                    <source srcSet={`${MaxImg} 1x, ${Max2xImg} 2x`} type="image/png" />
                                    <img className={'team-image'} src={MaxImg} alt={'Team'} />
                                </picture>
                                <StarIcon className={'team-star-icon'} />
                            </div>
                        </div>
                    )}
                </>
            </div>
        </div>
    );
};
export default Team;
