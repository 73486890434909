import './Hero.styles.css';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { ReactComponent as ArrowIcon } from '../../assets/svg/arrows/arrow-down.svg';
import { scrollToElementByID } from '../../functions/scrollToElementByID';
import { MobileCheck } from '../../typings/common';
import { Links } from '../../constants/links';

const Hero = ({ isMobile }: MobileCheck): JSX.Element => {
    const { t } = useTranslation();
    const clickButton = (): void => {
        window.open(`mailto:${Links.EMAIL}`);
    };
    return (
        <div className={'hero-layout'}>
            <div className={'app-container'}>
                <header className={isMobile ? 'flex-c' : 'flex-s-b'}>
                    <Logo className={'hero-logo'} />
                    {!isMobile && (
                        <button className={'contact-us'} onClick={clickButton} aria-label={'move to company block'}>
                            {t('buttons.contact-us')}
                        </button>
                    )}
                </header>
                <h1 className={isMobile ? 'hero-title-m' : 'hero-title'}>
                    <strong>{t('hero.bold')}</strong>
                    {t('hero.normal')}
                </h1>
                <div className={isMobile ? 'arrow-button-m' : 'arrow-button'}>
                    <button onClick={() => scrollToElementByID({ id: 'company', yOffset: 80 })}>
                        <ArrowIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Hero;
