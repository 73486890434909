import './Footer.styles.css';
import { useTranslation } from 'react-i18next';

import { Links } from '../../constants/links';
import { MobileCheck } from '../../typings/common';

const Footer = ({ isMobile }: MobileCheck): JSX.Element => {
    const { t } = useTranslation();
    return (
        <footer className={'footer-layout'}>
            <div className={'app-container'}>
                <div className={isMobile ? 'footer-sun-m' : 'footer-sun'} />
                <p className={isMobile ? 'footer-text-m' : 'footer-text'}>{t('footer.limited')}</p>
                <a className={isMobile ? 'footer-links-m' : 'footer-links'} href={`tel:${Links.PHONE}`}>
                    {Links.PHONE}
                </a>
                <a className={isMobile ? 'footer-links-m' : 'footer-links'} href={`mailto:${Links.EMAIL}`}>
                    {Links.EMAIL}
                    <p />
                </a>
                <p className={isMobile ? 'footer-text-m' : 'footer-text'}>
                    {t('footer.address', { address: Links.ADDRESS })}
                </p>
                <div className={isMobile ? 'divider-m' : 'divider'} />
                <div className={isMobile ? 'footer-icon-m' : 'footer-icon'} />
            </div>
        </footer>
    );
};
export default Footer;
