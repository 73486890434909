import React from 'react';

import useInitiateI18 from './hooks/configHooks/useInitiateI18';
import useMediaQuery from './hooks/utilHooks/useMediaQuery';
import './App.css';
import { Hero, Main, Team, OurApps, Footer } from './components';

function App() {
    useInitiateI18();
    const isMobile = useMediaQuery('(max-width: 1024px)');
    return (
        <div className="App">
            <Hero isMobile={isMobile} />
            <Main isMobile={isMobile} />
            <Team isMobile={isMobile} />
            <OurApps isMobile={isMobile} />
            <Footer isMobile={isMobile} />
        </div>
    );
}

export default App;
