import Moon from '../../assets/images/figures/moon.png';
import Sun from '../../assets/images/figures/sun.png';
import Lines from '../../assets/images/figures/lines.png';
import LPR from '../../assets/images/projects/lpr.png';
import Wallpapers from '../../assets/images/projects/wallpapers.png';
import Astroline from '../../assets/images/projects/astroline.png';

export const OurAppsData = [
    {
        icon: Wallpapers,
        iconName: 'astroline',
        bg: Moon,
        bgName: 'moon',
        texts: ['', '', ''],
        bgColor: '#E5E8F9',
        textColor: '#5A4CBE',
    },
    {
        icon: LPR,
        iconName: 'lpr',
        bg: Sun,
        bgName: 'sun',
        texts: ['', '', ''],
        bgColor: '#F4E0D3',
        textColor: '#915148',
    },
    {
        icon: Astroline,
        iconName: 'wallpapers',
        bg: Lines,
        bgName: 'lines',
        texts: ['', '', ''],
        bgColor: '#F5DEDE',
        textColor: '#E15454',
    },
];
