import './Main.styles.css';
import { useTranslation } from 'react-i18next';

import { MobileCheck } from '../../typings/common';

const Main = ({ isMobile }: MobileCheck): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={'main-layout'} id={'company'}>
            <div className={'app-container'}>
                <p className={isMobile ? 'common-text-m main-text-m' : 'common-text main-text'}>{t('main.text')}</p>
                <p className={isMobile ? 'common-text-m main-hint-m' : 'common-text main-hint'}>{t('main.hint')}</p>
            </div>
        </div>
    );
};
export default Main;
